<template>
  <div class="about-us">
    <div class="logo" v-if="oInfo.cover">
      <img :src="picHead + oInfo.cover" alt="" />
    </div>
    <div class="con">
      <div class="about-us-title">关于我们</div>
      <div class="texts">
        {{ oInfo.hostDesc ? oInfo.hostDesc : '暂无介绍信息' }}
      </div>
      <div class="about-us-title">联系我们</div>
      <div
        class="texts-con"
        v-html="oInfo.contactInfo"
        v-if="oInfo.contactInfo"
      ></div>
      <div class="texts-con" v-else>暂无联系信息</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  data() {
    return {
      oInfo: {},
    };
  },
  computed: {
    picHead() {
      return this.$store.state.picHead;
    },
  },
  created() {
    this.$request(this.$api.getAdminUsers).then((res) => {
      if (res.success) {
        this.oInfo = res.datas;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.about-us {
  min-height: calc(100vh - 283px);
  margin-bottom: 10px;
  background: white;
  .logo {
    // height: 278px;
    background: #f0f0f0;

    img {
      width: 100%;
      // height: 100%;
    }
  }
  .con {
    color: #101010;
    padding: 0 40px;

    .about-us-title {
      padding: 40px 0 20px;
      font-size: 28px;
      height: 41px;
      line-height: 41px;
      font-weight: bold;
    }
    .texts {
      font-size: 14px;
      line-height: 20px;
    }
    .texts-con {
      padding-bottom: 25px;
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
</style>
